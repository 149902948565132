import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";
import { Colour } from "./../types/colour.type";

export const getColours = (values: number[], samplingPointId: number): Promise<Colour[]> => {
  return axios
    .get<Colour>(`/colour/`, {
      params: {
        values: values,
        samplingPointId: samplingPointId,
      },
    })
    .then(responseHandlerDefault);
};
export const getColoursBySamplingPoint = (samplingPointId: number): Promise<Colour[]> => {
  return axios
    .get<Colour[]>(`/colour/bysamplingpoint/${samplingPointId}`, {
      params: {
        samplingPointId: samplingPointId,
      },
    })
    .then(responseHandlerDefault);
};
export const getColourById = (colourId: number, samplingPointId: number): Promise<Colour> => {
  return axios
    .get<Colour>(`/colour/byid/${colourId}/${samplingPointId}`, {
      params: {
        colourId: colourId,
        samplingPointId: samplingPointId,
      },
    })
    .then(responseHandlerDefault);
};
export const updateColourCode = (
  colour: string,
  samplingPointId: number,
  code: number
): Promise<Colour> => {
  return axios
    .post<Colour>(`/colour/updatecode`, {
      params: {
        colour: colour,
        samplingPointId: samplingPointId,
        code: code,
      },
    })
    .then(responseHandlerDefault);
};
