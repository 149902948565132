import { SamplingPoint } from "./../types/samplingpoint.type";
import { Tmoa } from "./../types/tmoa.type";
import { Filter } from "./../types/filter.type";
import { Pagination } from "./../types/pagination.type";
import { responseHandlerDefault } from "@/utils/handler.util";
import axios from "./api";
/**
 * get stations page
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */
export const getTmoa = (filter?: Filter): Promise<Tmoa[]> => {
  const params: { filter?: Filter } = {};

  if (filter) {
    params.filter = filter;
  }

  return axios.get(`/tmoa/`, { params }).then(responseHandlerDefault);
};

export const runScriptTmoa = (
  fromDate: Date,
  toDate: Date,
  samplingPoint: SamplingPoint
): Promise<void> => {
  console.log(fromDate);
  const params: { from: string; to: string; point: number | undefined } = {
    from: (fromDate as Date).toString().replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$3-$2-$1"),
    to: (toDate as Date).toString().replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$3-$2-$1"),
    point: samplingPoint.id,
  };

  console.log(params);
  return axios.get(`/tmoa/script`, { params }).then(responseHandlerDefault);
};

export const updateTmoa = (
  id: number | undefined,
  name: string | undefined,
  value: number | undefined
): Promise<any> => {
  const body = {
    id: id,
    name: name,
    value: value,
  };

  return axios.post(`tmoa/updateTmoa`, body).then(responseHandlerDefault);
};

export const updateTmoaUser = (
  id: number | undefined,
  name: string | undefined,
  description: string | undefined
): Promise<any> => {
  const body = {
    id: id,
    name: name,
    description: description,
  };

  return axios.post(`tmoa/updateTmoaUser`, body).then(responseHandlerDefault);
};
