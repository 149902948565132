import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_table, {
      data: _ctx.colours,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "colour",
          label: _ctx.$t('label.colour')
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                style: _normalizeStyle({
                backgroundColor: row.colour,
                width: '20px',
                height: '20px',
                display: 'inline-block',
                marginRight: '5px',
              })
              }, null, 4),
              _createTextVNode(" " + _toDisplayString(row.colour), 1)
            ])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "code",
          label: "Code"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_input_number, {
              modelValue: scope.row.code,
              "onUpdate:modelValue": ($event: any) => ((scope.row.code) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}