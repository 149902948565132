
import { getParameter, getParameterClient } from "@/service/parameter.service";
import { Filter } from "@/types/filter.type";
import { Pagination } from "@/types/pagination.type";
import { Parameter } from "@/types/parameter.type";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    parameters: {
      type: Object as () => Parameter[],
      required: true,
    },
  },
  data() {
    return {
      parametersPagination: {} as Pagination<Parameter>,
      parametersList: [] as Parameter[],
      parametersListVisualice: [] as Parameter[],
      selectedParametersId: [] as number[],
      selectedParameters: [] as Parameter[],
      isIndeterminate: true,
      findParameter: "" as string,
    };
  },
  methods: {
    async searchParameter() {
      this.parametersListVisualice = this.parametersPagination.data.filter((parameter) => {
        let nombre = parameter.name?.toLocaleLowerCase();
        return nombre?.includes(this.findParameter);
      });
    },
    handleCheckedParametersChange(value: string[]) {
      console.log("checkeo y o deschekeo");
      const checkedCount = value.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.parametersList.length;
    },
    saveParameter() {
      console.log("SAVES parametros");
      for (const parameter of this.parametersList) {
        if (parameter.id && this.selectedParametersId.includes(parameter.id)) {
          this.selectedParameters.push(parameter);
        }
      }
      console.log("selecionados", this.selectedParameters);
      return this.selectedParameters;
    },
    pushtIdParametersSelected() {
      const parameters = Object.assign([], this.parameters) as Parameter[];
      for (const p of parameters) {
        if (p.id) {
          this.selectedParametersId.push(p.id);
        }
      }
    },
    pushIdParameters() {
      this.parametersList.forEach((parameter) => {
        if (parameter.id) {
          this.selectedParametersId.push(parameter.id);
        }
      });
    },
  },
  async mounted() {
    const filter: Filter = {
      fields: {
        "parameter.active": { operator: "equal", value: true },
        "parameter.client": { operator: "equal", value: true },
      },
    };
    this.parametersPagination = await getParameterClient(undefined, undefined, filter);
    console.log("LOAD1 parametros clientes = ", this.parametersPagination);

    this.parametersList = this.parametersPagination.data;
    this.parametersListVisualice = this.parametersList;
    console.log("LOAD2 parametros clientes = ", this.parametersList);
    console.log("LOAD3 parametros clientes = ", this.parametersListVisualice);
    if (this.parameters) {
      this.pushtIdParametersSelected();
    } else {
      this.pushIdParameters();
      this.isIndeterminate = false;
    }
  },
});
