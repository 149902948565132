
import { defineComponent, ref, PropType, toRefs, onMounted } from "@vue/runtime-core";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { getColoursBySamplingPoint, updateColourCode } from "@/service/colour.service";
import { Colour } from "@/types/colour.type";
import { watch } from "vue";

export default defineComponent({
  name: "ColourDialogComponent",
  props: {
    samplingPointForm: {
      type: Object as PropType<SamplingPoint>,
      required: true,
    },
    onClose: Function,
  },
  methods: {
    async saveCode(colour: Colour) {
      console.log("colour", colour);
      try {
        if (colour.colour && colour.samplingPointId && colour.code) {
          console.log("color", colour.colour);
          console.log("sampid", colour.samplingPointId);
          console.log("code", colour.code);
          await updateColourCode(colour.colour, colour.samplingPointId, colour.code);
          this.$emit("code-updated", colour.code);
        }
      } catch (error) {
        console.error("Error updating colour code:", error);
      }
    },
    handleClose() {
      if (this.onClose) {
        if (this.updatedCode !== 0) {
          this.onClose(this.updatedCode);
        } else {
          this.colours = [];
          this.updatedCode = 0;
        }
      }
      console.log("updatedCode", this.updatedCode);
    },
  },
  setup(props) {
    const { samplingPointForm } = toRefs(props);

    const samplingPointId = ref<number>(samplingPointForm.value?.id || 0);

    const colours = ref([] as Colour[]);
    const updatedCode = ref(0);

    onMounted(async () => {
      colours.value = [];
      if (samplingPointId.value !== 0) {
        await getAndSetColours();
      }
    });

    const getAndSetColours = async () => {
      try {
        const result = await getColoursBySamplingPoint(samplingPointId.value);

        if (Array.isArray(result)) {
          colours.value = result as Colour[];
        } else {
          colours.value = [];
        }
      } catch (error) {
        console.error("Error fetching colours:", error);
      }
    };

    console.log("colours", colours);
    return { colours, updatedCode };
  },
});
