
import { getParameter } from "@/service/parameter.service";
import { Filter } from "@/types/filter.type";
import { Pagination } from "@/types/pagination.type";
import { Parameter } from "@/types/parameter.type";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    parameters: {
      type: Object as () => Parameter[],
      required: true,
    },
    hasAllParameters: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      parametersPagination: {} as Pagination<Parameter>,
      parametersList: [] as Parameter[],
      parametersListVisualice: [] as Parameter[],
      checkedAll: true,
      selectedParametersId: [] as number[],
      selectedParameters: [] as Parameter[],
      isIndeterminate: true,
      findParameter: "" as string,
    };
  },
  methods: {
    async searchParameter() {
      this.parametersListVisualice = this.parametersPagination.data.filter((parameter) => {
        let nombre = parameter.name?.toLocaleLowerCase();
        return nombre?.includes(this.findParameter);
      });
    },
    handleCheckedAllChange(val: boolean) {
      if (val) {
        this.pushIdParameters();
      } else {
        this.selectedParametersId = [];
        this.checkedAll = false;
      }

      this.isIndeterminate = false;
    },
    handleCheckedParametersChange(value: string[]) {
      const checkedCount = value.length;
      this.checkedAll = checkedCount === this.parametersList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.parametersList.length;
    },
    saveParameter() {
      for (const parameter of this.parametersList) {
        if (parameter.id && this.selectedParametersId.includes(parameter.id)) {
          this.selectedParameters.push(parameter);
        }
      }
      return this.selectedParameters;
    },
    getCheckedAll() {
      return this.checkedAll;
    },
    pushtIdParametersSelected() {
      const parameters = Object.assign([], this.parameters) as Parameter[];
      for (const p of parameters) {
        if (p.id) {
          this.selectedParametersId.push(p.id);
        }
      }
    },
    pushIdParameters() {
      this.parametersList.forEach((parameter) => {
        if (parameter.id) {
          this.selectedParametersId.push(parameter.id);
        }
      });
    },
    async refresData() {
      const filter: Filter = {
        fields: {
          "parameter.active": { operator: "equal", value: true },
          "parameter.client": { operator: "equal", value: false },
          "parameter.type": { operator: "equal", value: "SAMPLINGPOINT" },
        },
      };
      this.parametersPagination = await getParameter(undefined, undefined, filter);
      this.parametersList = this.parametersPagination.data;
      this.findParameter = "";
    },
  },
  async mounted() {
    const filter: Filter = {
      fields: {
        "parameter.active": { operator: "equal", value: true },
        "parameter.client": { operator: "equal", value: false },
        "parameter.type": { operator: "equal", value: "SAMPLINGPOINT" },
      },
    };
    this.parametersPagination = await getParameter(undefined, undefined, filter);
    this.parametersList = this.parametersPagination.data;
    this.parametersListVisualice = this.parametersList;
    if (this.parameters && !this.hasAllParameters) {
      this.pushtIdParametersSelected();
      this.checkedAll = false;
    } else {
      this.pushIdParameters();
      this.isIndeterminate = false;
    }
  },
});
