import { CameraConfiguration, ProcessingTime } from "@/types/cameraconfiguration.type";

import axios from "./api";
import { responseHandlerDefault } from "@/utils/handler.util";

/**
 * do login
 * @param username
 * @param password
 * @returns
 */
export const getCameraConfiguration = (
  stationId: number,
  camera: string,
  numCamera: number | undefined
): Promise<CameraConfiguration> => {
  const params: { station: number; camera: string; num?: number } = {
    station: stationId,
    camera: camera,
  };

  if (numCamera) {
    params.num = numCamera;
  }

  return axios
    .get<CameraConfiguration>(`/cameraconfiguration/`, { params })
    .then(responseHandlerDefault);
};

export const getCamerasConfigurationByStationId = (
  stationId: number
): Promise<CameraConfiguration[]> => {
  const params: { station: number } = {
    station: stationId,
  };

  return axios
    .get<CameraConfiguration[]>(`/cameraconfiguration/station/`, { params })
    .then(responseHandlerDefault);
};

/**
 * do login
 * @param username
 * @param password
 * @returns
 */
export const saveCameraConfiguration = (
  cameraConfiguration: CameraConfiguration
): Promise<CameraConfiguration> => {
  return axios
    .post<CameraConfiguration>(`/cameraconfiguration/`, cameraConfiguration)
    .then(responseHandlerDefault);
};

export const updateCameraModelStation = (
  cameraId: number,
  modele: boolean,
  stationId: number,
  imageProcessingTime: ProcessingTime | undefined
): Promise<CameraConfiguration> => {
  return axios
    .post<CameraConfiguration>("/cameraconfiguration/update", {
      cameraId: cameraId,
      modele: modele,
      stationId: stationId,
      imageProcessingTime: imageProcessingTime,
    })
    .then(responseHandlerDefault);
};
