import { Parameter } from "./../types/parameter.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import { Filter } from "./../types/filter.type";
import axios from "./api";
import { Pagination } from "./../types/pagination.type";
import { ChartData } from "./../types/chartData.type";

const paramRoute = "/parameter/";

export const saveParameter = (parameter: Parameter): Promise<Parameter> => {
  if (parameter.id === undefined) {
    return axios.post<Parameter>(paramRoute, parameter).then(responseHandlerDefault);
  } else {
    return axios.put<Parameter>(paramRoute, parameter).then(responseHandlerDefault);
  }
};

export const getParameter = (
  page?: number,
  size?: number,
  filter?: Filter
): Promise<Pagination<Parameter>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  return axios.get<Pagination<Parameter>>(paramRoute, { params }).then(responseHandlerDefault);
};

export const getParameterList = (
  page?: number,
  size?: number,
  filter?: Filter
): Promise<Pagination<Parameter>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  return axios
    .get<Pagination<Parameter>>(paramRoute + `parameterList`, { params })
    .then(responseHandlerDefault);
};

export const getParameterClient = (
  page?: number,
  size?: number,
  filter?: Filter
): Promise<Pagination<Parameter>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }
  return axios
    .get<Pagination<Parameter>>(paramRoute + `getParametersClientBySamplingpoint`, { params })
    .then(responseHandlerDefault);
};

export const getParameterData = (
  parameterId: number,
  samplingPointId: number,
  from?: Date,
  until?: Date
): Promise<ChartData[]> => {
  if (from && until) {
    return axios
      .get<ChartData[]>(
        `/parameter/results?samplingPoint=${samplingPointId}&parameter=${parameterId}&from=${from.toISOString()}&until=${until.toISOString()}`
      )
      .then(responseHandlerDefault);
  } else {
    return axios
      .get<ChartData[]>(
        `/parameter/results?samplingPoint=${samplingPointId}&parameter=${parameterId}`
      )
      .then(responseHandlerDefault);
  }
};

/**
 * set parameter status
 * @param id
 * @param active
 * @returns
 */
export const setParameterActive = (id: number, active: boolean): Promise<Parameter> => {
  return axios
    .put<Parameter>(`/parameter/${id}/${active ? "activate" : "deactivate"}`)
    .then(responseHandlerDefault);
};
