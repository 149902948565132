
import { getContainerNames, getContainerById, getAllContainer } from "@/service/container.service";
import { getStationNames } from "@/service/station.service";
import {
  postSamplingPoint,
  getSamplingPointById,
  getSamplingPointsList,
  putSamplingPoint,
} from "@/service/samplingpoint.service";
import { IdName } from "@/types";
import { Container, ContainerType } from "@/types/container.type";
import { Station } from "@/types/station.type";
import { defineComponent, ref } from "@vue/runtime-core";
import { ElForm, ElRow } from "element-plus";
import { AppError } from "@/types/app.type";
import ParameterDialogComponent from "../parameter/parameterDialog.component.vue";
import ParameterDialogComponentClient from "../parameter/parameterDialogClient.component.vue";
import ColourDialogComponent from "../colour/colourDialog.component.vue";
import { SamplingPoint, SamplingPointType } from "@/types/samplingpoint.type";
import { Colour } from "@/types/colour.type";
import { updateColourCode } from "@/service/colour.service";
import { Filter } from "@/types/filter.type";
import {
  getCamerasConfigurationByStationId,
  updateCameraModelStation,
} from "@/service/cameraConfiguration.service";
import { CameraConfiguration, ProcessingTime } from "@/types/cameraconfiguration.type";
import { Tmoa } from "@/types/tmoa.type";
import { getTmoa, updateTmoa } from "@/service/tmoa.service";
import { sortByType } from "@/utils/tmoa.util";
import { ElMessage } from "element-plus";

const errorMessage = "error.required";

export default defineComponent({
  components: {
    ParameterDialogComponent,
    ColourDialogComponent,
    ElRow,
    ParameterDialogComponentClient,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const processingTime = ref<ProcessingTime>();

    return { processingTime };
  },
  data() {
    return {
      station: {} as Station,
      camerasConfiguration: [] as Array<CameraConfiguration>,
      containers: [] as Container[],
      stations: [] as IdName[],
      samplingPointForm: {} as SamplingPoint,
      tmoas: [] as Tmoa[],
      selectedSamplingPointTmoas: [] as Tmoa[],
      selectedSamplingPoint: 0,
      disableNumTmoa: false,
      showParameters: false,
      showColour: false,
      showCamera: false,
      showTmoas: false,
      showParametersClient: false,
      activeModel: false,
      throughModel: false,
      switchSamplintPointType: false,
      sequence: 1,
      minBlack: 0,
      maxWhite: 0,
      labellingScript: true,
      modify: false,
      options: [
        { label: "Nuevo", value: true },
        { label: "Antiguo", value: false },
      ],
      hemisphereList: [
        { label: "N", name: "north" },
        { label: "S", name: "south" },
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        hemisphere: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        typeDischarge: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        utmX: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        utmY: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        timeZone: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        container: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
        samplingPointType: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: "blur",
          },
        ],
      },
    };
  },
  async mounted() {
    if (this.id) {
      this.selectedSamplingPoint = this.id;
      this.samplingPointForm = await getSamplingPointById(this.id);
      this.station = this.samplingPointForm.station as Station;
      if (this.station !== null && this.station.id !== undefined) {
        this.camerasConfiguration = await getCamerasConfigurationByStationId(this.station.id);
      }
      this.modify = true;
    } else {
      this.samplingPointForm = {} as SamplingPoint;
      this.samplingPointForm.hasAllParameters = true;
      this.samplingPointForm.parameters = [];
      this.samplingPointForm.parametersClient = [];
      this.samplingPointForm.minBlack = 59; //Default
      this.samplingPointForm.maxWhite = 220; //Default
      this.samplingPointForm.labellingScript = true; //Default
      this.samplingPointForm.throughModel = false; //Default
    }
    this.samplingPointForm.visible = true;
    // this.containers = await getContainerNames();
    this.containers = await getAllContainer();
    this.stations = await getStationNames();
    this.tmoas = await getTmoa();
    this.tmoas = sortByType(this.tmoas);

    if (this.samplingPointForm.numTmoa && this.samplingPointForm.numTmoa > 0) {
      this.disableNumTmoa = true;
    }
  },
  methods: {
    async doSave(): Promise<SamplingPoint> {
      const valid = await (this.$refs["form"] as InstanceType<typeof ElForm>).validate();
      if (!valid) {
        throw new Error(AppError.FORM_VALIDATION);
      }
      let samplingPoint;
      if (this.samplingPointForm.id === undefined) {
        samplingPoint = await postSamplingPoint(this.samplingPointForm);
      } else {
        this.samplingPointForm.yBright = this.samplingPointForm.yBright
          ? this.samplingPointForm.yBright
          : undefined;
        this.samplingPointForm.xBright = this.samplingPointForm.xBright
          ? this.samplingPointForm.xBright
          : undefined;
        this.samplingPointForm.dyBright = this.samplingPointForm.dyBright
          ? this.samplingPointForm.dyBright
          : undefined;
        this.samplingPointForm.dxBright = this.samplingPointForm.dxBright
          ? this.samplingPointForm.dxBright
          : undefined;
        samplingPoint = await putSamplingPoint(this.samplingPointForm);
      }

      // console.log(`[DEBUG] samplinpoint = `, samplingPoint);
      return samplingPoint;
    },
    changeCamera(camera: CameraConfiguration) {
      if (!camera.modele) {
        camera.imageProcessingTime = undefined;
      }
    },
    ckeckCamera(camera: CameraConfiguration) {
      let processTime = [] as string[];
      if (camera?.imageProcessingTime) {
        this.camerasConfiguration.forEach((_) => {
          if (_?.imageProcessingTime) {
            if (_?.imageProcessingTime && !processTime.includes(_?.imageProcessingTime)) {
              if (
                (_?.imageProcessingTime === "ALL_DAY" &&
                  (processTime.includes("DAY") || processTime.includes("NIGHT"))) ||
                ((_?.imageProcessingTime === "DAY" || _?.imageProcessingTime === "NIGHT") &&
                  processTime.includes("ALL_DAY"))
              ) {
                ElMessage.warning(this.$t("warning.confNotAllowed"));
                camera.imageProcessingTime = undefined;
              } else {
                processTime.push(_?.imageProcessingTime);
              }
            } else {
              ElMessage.warning(this.$t("warning.confProcessingTime"));
              camera.imageProcessingTime = undefined;
            }
          }
        });
      }
    },
    async updateCamerasModelStation(cameras: CameraConfiguration[]) {
      // Comprobación de que este completado correctamente
      let canConfiguration = true;
      cameras.forEach(async (camera) => {
        if (camera.modele && !camera.imageProcessingTime && camera.camera?.name) {
          ElMessage.warning(this.$t("warning.confNotCorrectTime", { code: camera.camera.name }));
          canConfiguration = false;
        }
      });
      if (canConfiguration) {
        cameras.forEach(async (camera) => {
          await updateCameraModelStation(
            camera.id as number,
            camera.modele as boolean,
            camera.station?.id as number,
            camera.imageProcessingTime
          );
        });
        this.showCamera = false;
      }
    },
    updateTmoas() {
      this.tmoas.forEach(async (tmoa) => {
        await updateTmoa(tmoa.id, tmoa.name, tmoa.value);
      });
      this.showTmoas = false;
    },
    openDialogParameter() {
      this.showParameters = true;
    },
    openDialogParameterClient() {
      this.showParametersClient = true;
    },
    openDialogColour() {
      this.showColour = true;
    },
    openDialogCamera() {
      this.showCamera = true;
    },
    openDialogTmoas() {
      this.showTmoas = true;
    },
    saveParameters() {
      const checkedAll = (
        this.$refs["parameterDialog"] as InstanceType<typeof ParameterDialogComponent>
      ).getCheckedAll();
      const selectedParameters = (
        this.$refs["parameterDialog"] as InstanceType<typeof ParameterDialogComponent>
      ).saveParameter();

      this.samplingPointForm.hasAllParameters = checkedAll;
      this.samplingPointForm.parameters = selectedParameters;

      this.showParameters = false;

      return this.samplingPointForm.parameters;
    },
    saveParametersClient() {
      const selectedParametersClient = (
        this.$refs["parameterDialog"] as InstanceType<typeof ParameterDialogComponentClient>
      ).saveParameter();

      this.samplingPointForm.parametersClient = selectedParametersClient;

      this.showParametersClient = false;

      return this.samplingPointForm.parametersClient;
    },

    saveCodeFromChild() {
      const colourDialog = this.$refs.colourDialog as typeof ColourDialogComponent | undefined;
      if (colourDialog) {
        const colours = colourDialog.colours;

        if (colours) {
          for (const colour of colours) {
            colourDialog.saveCode(colour);
          }
        }
      }
      this.showColour = false;
    },
    /**
     * close samplingPoint formDialog
     */
    closeEntityDialog() {
      this.showParameters = false;
      this.showParametersClient = false;
      this.showColour = false;
      this.showCamera = false;
      this.showTmoas = false;
    },
    async checkSamplintPointType() {
      if (this.samplingPointForm.container) {
        if (
          this.samplingPointForm.container.containerType?.samplingPointType !== undefined &&
          this.samplingPointForm.container.containerType?.samplingPointType.length > 0
        ) {
          this.switchSamplintPointType = true;
          this.makesequence();
        } else {
          this.makesequence();
          this.switchSamplintPointType = false;
        }
      } else {
        this.makesequence();
        this.switchSamplintPointType = false;
      }
      this.containers = await getAllContainer();
      if (this.samplingPointForm.samplingPointType) {
        this.samplingPointForm.samplingPointType = undefined;
        this.makesequence();
      }
    },
    async makesequence() {
      this.sequence = 1;
      if (
        this.switchSamplintPointType &&
        this.samplingPointForm.samplingPointType &&
        this.samplingPointForm.samplingPointType.name
      ) {
        const filter: Filter = {
          fields: {
            "samplingPoint.containerId": {
              operator: "equal",
              value: this.samplingPointForm.container?.id,
            },
            "samplingPointType.name": {
              operator: "equal",
              value: this.samplingPointForm.samplingPointType.name,
            },
          },
        };
        const listaSamplinpoint = await getSamplingPointsList(
          undefined,
          undefined,
          filter,
          undefined
        );
        this.sequence = listaSamplinpoint.data.length + 1;
        this.makeName();
        this.makeCode();
      } else {
        this.sequence = 1;
        const filter: Filter = {
          fields: {
            "samplingPoint.containerId": {
              operator: "equal",
              value: this.samplingPointForm.container?.id,
            },
          },
        };
        const listaSamplinpoint = await getSamplingPointsList(
          undefined,
          undefined,
          filter,
          undefined
        );
        this.sequence = listaSamplinpoint.data.length + 1;
        this.makeName();
        this.makeCode();
      }
    },
    makeName() {
      let containerName: string | undefined = "";
      let samplingPointType: string | undefined = "";
      let sequence = this.sequence == 1 ? "" : this.sequence;
      if (this.samplingPointForm.container?.name) {
        if (this.switchSamplintPointType && this.samplingPointForm.samplingPointType) {
          containerName = this.samplingPointForm.container?.name.trim();
          samplingPointType = this.samplingPointForm.samplingPointType.name?.trim();
          if (sequence >= 2) {
            this.samplingPointForm.name = `${containerName} ${samplingPointType} ${sequence}`;
          } else {
            this.samplingPointForm.name = `${containerName} ${samplingPointType}`;
          }
        } else {
          containerName = this.samplingPointForm.container?.name.trim();
          if (sequence >= 2) {
            this.samplingPointForm.name = `${containerName} ${sequence}`;
          } else {
            this.samplingPointForm.name = `${containerName}`;
          }
        }
      }
      // console.log(`[NAME] NOMBRE`, this.samplingPointForm.name);
    },
    makeCode() {
      let typeContainer: string | undefined = "";
      let location: string | undefined = "";
      let typeSamplingPoint: string | undefined = "";
      let sequence = this.sequence == 1 ? "" : this.sequence;
      if (this.switchSamplintPointType) {
        const container = this.samplingPointForm.container as Container;
        if (container) {
          location = container.locality
            ?.trim()
            .replace(/\s+/g, "")
            .substring(0, 3)
            .toLocaleUpperCase();
          typeContainer = container.containerType?.name
            .trim()
            .replace(/\s+/g, "")
            .substring(0, 4)
            .toLocaleUpperCase();
        }
        if (
          this.samplingPointForm.samplingPointType !== undefined &&
          this.samplingPointForm.samplingPointType.name !== undefined
        ) {
          if (
            this.samplingPointForm.samplingPointType?.name === "Decantacion 2" ||
            this.samplingPointForm.samplingPointType?.name === "Decantacion 1"
          ) {
            typeSamplingPoint = this.samplingPointForm.samplingPointType?.name
              .trim()
              .replace(/\s+/g, "")
              .substring(0, 2)
              .toLocaleUpperCase();
            typeSamplingPoint += this.samplingPointForm.samplingPointType?.name
              .trim()
              .substring(
                this.samplingPointForm.samplingPointType?.name.length,
                this.samplingPointForm.samplingPointType?.name.length - 1
              )
              .toLocaleUpperCase();
          } else {
            typeSamplingPoint = this.samplingPointForm.samplingPointType?.name
              .trim()
              .replace(/\s+/g, "")
              .substring(0, 3)
              .toLocaleUpperCase();
          }
          // console.log(`[debug]tiipo samongPoint`, typeSamplingPoint);
        }
        if (sequence >= 2) {
          this.samplingPointForm.code = `${typeContainer}_${location}_${typeSamplingPoint}_${sequence}`;
        } else {
          this.samplingPointForm.code = `${typeContainer}_${location}_${typeSamplingPoint}`;
        }
      } else {
        const container = this.samplingPointForm.container as Container;
        typeContainer =
          this.samplingPointForm.container !== undefined
            ? this.samplingPointForm.container.containerType?.name
                .trim()
                .replace(/\s+/g, "")
                .substring(0, 4)
                .toLocaleUpperCase()
            : "";
        if (container) {
          location = container.locality
            ?.trim()
            .replace(/\s+/g, "")
            .substring(0, 3)
            .toLocaleUpperCase();
        }
        if (sequence >= 2) {
          this.samplingPointForm.code = `${typeContainer}_${location}_${sequence}`;
        } else {
          this.samplingPointForm.code = `${typeContainer}_${location}`;
        }
      }
      let noAccents = this.removeAccents(this.samplingPointForm.code);
      this.samplingPointForm.code = noAccents;
      // console.log(`[CODE]`, this.samplingPointForm.code);
    },
    removeAccents(string: string) {
      var mapAccents: any = {
        á: "a",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
      };
      return string.replace(/[áéíóúÁÉÍÓÚ]/g, function (letter: string) {
        return mapAccents[letter];
      });
    },
  },
});
