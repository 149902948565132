
import { debounce } from "lodash";
import { defineComponent } from "vue";
import { Action, ElMessage, ElMessageBox } from "element-plus";
import { Filter } from "@/types/filter.type";
import { Container } from "@/types/container.type";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { ElRowClassNameParams } from "@/types/element.type";
import PageHeaderComponent from "../PageHeader.component.vue";
import SamplingPointFormComponent from "@/components/samplingPoints/SamplingPointForm.component.vue";
import { getSamplingPoints, setSamplingPointStatus } from "@/service/samplingpoint.service";

export default defineComponent({
  components: { PageHeaderComponent, SamplingPointFormComponent },
  props: {
    canWrite: {
      type: Boolean,
      default: false,
    },
    containerId: {
      type: Number,
    },
    detailRouterName: {
      type: String,
    },
    onClick: {
      type: Function,
      required: false,
    },
    appIsAdminAndRoleIsAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      entities: [] as SamplingPoint[],
      containers: [] as Container[],
      currentPage: 0 as number,
      rowPerPage: 10 as number,
      totalRows: 0 as number,
      query: "" as string,
      showEntityForm: false,
      showEntityInfo: false,
      samplingPointSelected: undefined as undefined | SamplingPoint,
    };
  },
  created() {
    // - apply delay
    this.search = debounce(this.refreshEntities, 300);
  },
  mounted() {
    this.currentPage = 1;
    this.refreshEntities();
  },
  methods: {
    search(): void {
      this.refreshEntities();
    },
    /**
     * table row css class name
     * @param params
     * @return css class name
     */
    tableRowClassName(params: ElRowClassNameParams<SamplingPoint>): string {
      return params.row && params.row.status ? "" : "disable-row";
    },

    /**
     * set page
     * @param page
     */
    changePaginationSize(size: number): void {
      this.rowPerPage = size;
      this.refreshEntities();
    },

    /**
     * change page
     * @param {number} page
     */
    changePaginationPage(page: number): void {
      this.currentPage = page;
      this.refreshEntities();
    },

    /**
     * confirm activate item
     * @param item
     */
    confirmActivateItem(item: SamplingPoint) {
      ElMessageBox.confirm(
        this.$t("confirm.activateSamplingPoint", { name: item.name }),
        this.$t("label.warning"),
        {
          confirmButtonText: this.$t("btn.accept"),
          cancelButtonText: this.$t("btn.cancel"),
          type: "warning",
          dangerouslyUseHTMLString: true,
          callback: (action: Action) => {
            if (action === "confirm") {
              this.changeStatusEntity(item, true);
            }
          },
        }
      );
    },

    /**
     * confirm deactivate action
     * @param item
     */
    confirmDeactivateItem(item: SamplingPoint) {
      ElMessageBox.confirm(
        this.$t("confirm.deactivateSamplingPoint", { name: item.name }),
        this.$t("label.warning"),
        {
          confirmButtonText: this.$t("btn.accept"),
          cancelButtonText: this.$t("btn.cancel"),
          type: "warning",
          dangerouslyUseHTMLString: true,
          callback: (action: Action) => {
            if (action === "confirm") {
              this.changeStatusEntity(item, false);
            }
          },
        }
      );
    },

    /**
     * change status samplingPoint
     * @param item
     * @param active
     */
    async changeStatusEntity(item: SamplingPoint, active: boolean) {
      if (!item.id) {
        return;
      }
      await setSamplingPointStatus(item.id, active);
      ElMessage.success(this.$t("success.action"));

      // - refrehs item
      this.refreshEntities();
    },

    /**
     * refresh samplingPoint item from list items
     * @param item
     */
    async refreshEntities(item: SamplingPoint | undefined = undefined) {
      let index = -1;
      if (this.entities && item) {
        index = this.entities.findIndex((i) => i.id === item.id);
      }
      if (index !== -1 && item) {
        this.entities[index] = item;
      } else {
        const filter: Filter = {};
        if (this.query) {
          filter.multi = this.query;
        }
        if (this.containerId) {
          filter.fields = { "container.id": { operator: "equal", value: this.containerId } };
        }

        const pagination = await getSamplingPoints(this.currentPage, this.rowPerPage, filter);
        this.totalRows = pagination.totalRows;
        this.entities = pagination.data;
      }
    },

    /**
     * open samplingPoint formDialog
     * @param item
     */
    openEntityFormDialog(item: SamplingPoint | undefined = undefined) {
      this.samplingPointSelected = item;
      this.showEntityForm = true;
    },

    /**
     * close samplingPoint formDialog
     */
    closeEntityDialog() {
      this.showEntityForm = false;
      this.showEntityInfo = false;
      this.samplingPointSelected = undefined;
    },

    /**
     * save samplingPoint
     */
    async saveEntity() {
      // - call samplingPointForm save method
      await (
        this.$refs["samplingPointForm"] as InstanceType<typeof SamplingPointFormComponent>
      ).doSave();

      // - close dialog and refresh
      this.closeEntityDialog();
      this.refreshEntities();
      ElMessage.success(this.$t("success.action"));
    },

    openSamplingPointDetail(samplingPoint: SamplingPoint) {
      const container = samplingPoint.container as Container | undefined;
      if (
        container?.id &&
        this.$route.path.includes("support") &&
        !this.$route.path.includes("samplingPoints/")
      ) {
        if (this.$route.path.includes("samplingPoints")) {
          this.$router.push(
            "samplingPoints/" + container?.id + "?samplingPoint=" + samplingPoint.id
          );
        } else {
          this.$router.push(
            "/support/samplingPoints/" + container?.id + "?samplingPoint=" + samplingPoint.id
          );
        }
      } else if (container?.id && this.detailRouterName) {
        this.$router.push({
          name: this.detailRouterName,
          params: { containerid: container.id },
        });
      } else if (this.onClick !== undefined) {
        this.onClick(samplingPoint);
      }
    },
  },
});
